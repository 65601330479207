html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: white;
  min-height: 100vh;
}

body {
  padding-top: 30px;
  background: url(../img/mind_vibes.png) center top / cover no-repeat, -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.85)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.85));
  background-blend-mode: overlay;
}


#hero,
header {
  max-width: 1238px;
  margin: 0 auto;
}

#hero #content__right {
  text-align: end;
}

#hero #content__right img {
  max-width: 500px;
}

#hero {
  margin-bottom: 75px;
}

.explanation {
  background-color: #fffac759;
}

#languageSwitcher {
  background-color: black;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  color: white;
  font-family: 'roboto';
  text-align: left;
}

a {
  text-decoration: none;
  transition: 0.2s ease all;
}

/* Utility Classes */
.centered {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

article p,
article ul {
  max-width: 1280px;
  line-height: 36px;
  text-align: center;
  margin: 0 auto;
  padding: 0 15px;
}

dl {
  max-width: 1000px;
  line-height: 30px;
  text-align: left;
  margin: 0 auto;
  padding: 0 15px;
}

/* Header Styles */
#logo {
  display: flex;
  align-items: center;
}

body:not(.inner) #logo {
  background-color: #fad9b9;
  padding: 10px;
  border-radius: 7px;
}

#logo img {
  width: 225px;
}

#menu {
  margin-left: auto;
  color: white;
  font-family: 'roboto-bold';
  font-size: 18px;
  /* display: none; */
}

h2,
h3,
h4 {
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

strong {
  font-family: 'roboto-bold';
  text-transform: uppercase;
}

b {
  font-weight: 900;
  font-family: 'roboto-bold';
}

h2 {
  font-size: 22px;
  line-height: 36px;
}

h3 {
  font-size: 28px;
  margin-bottom: 40px;
  padding-bottom: 10px;
  font-family: 'roboto-bold';
  text-transform: uppercase;
}

h4 {
  font-size: 20px;
  font-family: 'roboto-bold';
  text-transform: uppercase;
}

p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
  font-weight: 300;
  letter-spacing: 0.8px;
}

b {
  font-weight: 500;
  letter-spacing: 1px;
}

article {
  color: #000;
  text-align: center;
  background-color: #ffffff1a;
  width: 100%;
  padding: 40px;
  display: block;
}

article:nth-child(1) {
  border-bottom: none;
}

article:nth-child(2) {
  background-color: beige;
}

article:nth-child(4) {
  background-color: #1c4286;
  color: white;
}

article:nth-child(6) {
  background-color: cornsilk;
}

article:nth-child(8) {
  /* background-color: #53b94e; */
  background-color: aliceblue;
}

article:last-child {
  border-bottom: none;
}

.explanation p,
.challenge p,
.solution p,
.why-choose p,
.begin-journey p,
.testimonials p,
.join-us p,
.faqs p,
.ready-to-manifest p {
  max-width: 825px;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 20px;
}

.ready-to-manifest .content__links,
.begin-journey .content__links {
  place-content: center;
}

.ready-to-manifest .content__links img {
  filter: brightness(0) saturate() invert(7%) sepia(21%) saturate(124%) hue-rotate(300deg) brightness(115%) contrast();
}

.ready-to-manifest .content__links img:hover {
  filter: invert(48%) sepia(100%) saturate(1713%) hue-rotate(159deg) brightness(91%) contrast(101%);
}

.content__languages {
  margin-top: 20px;
}

.content__languages p {
  font-family: 'roboto-bold';
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;
  color: white;
  display: inline-block;
}

.content__languages>div {
  display: inline-block;
  margin-top: 10px;
}

.content__languages img {
  width: 30px;
  margin: 0 10px 10px 0;
  border-radius: 2px;
}

.begin-journey h4 {
  color: yellow;
}

ul li>p {
  max-width: 320px !important;
}

ul {
  padding-left: 20px;
}

ul li {
  margin-bottom: 20px;
  display: inline-block;
  padding: 20px;
  width: 29%;
}

ul li strong {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
}

#content__left,
#content__right {
  padding: 10px;
  border-radius: 5px;
}

.yellow {
  color: yellow;
}

.brand {
  color: #fed4ad;
}

#content__right {
  flex: 5;
}

#content__left {
  flex: 5;
  padding: 70px 15px;
}

#content__left .coming__soon {
  margin: 50px 0 0 0;
}

.content__left ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.content__left li {
  box-sizing: border-box;
  flex: 1;
  min-width: calc(33.33% - 40px);
}


#content__right {
  padding-right: 30px;
}

#content__right img {
  width: 710px;
  margin-bottom: -34px;
}

#content__white {
  overflow: hidden;
  background-color: white;
}

#content__white .centered {
  display: flex;
  flex-direction: column;
}

#content__left h1 {
  font-family: 'roboto-bold';
  color: white;
  letter-spacing: 1px;
  font-size: 48px;
}

#content__left h4 {
  font-family: 'roboto';
  color: white;
  letter-spacing: 1.2px;
  line-height: 42px;
  font-size: 24px;
}

.content__links {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}

.challenge {
  background: url('../img/struggling3.png');
  background-position: -120px 0;
  background-size: 540px;
  background-repeat: no-repeat;
}

.testimonials .testimonial-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  max-width: 1100px;
  margin: 0 auto;
}

.testimonials blockquote {
  margin-bottom: 20px;
  font-style: italic;
  position: relative;
  padding-left: 30px;
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  flex: 1;
  text-align: start;
}

.testimonials blockquote:before {
  content: '“';
  font-size: 40px;
  position: absolute;
  left: 0;
  top: -10px;
}

.testimonials .testimonial-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

.testimonials p {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.solution img {
  max-width: 475px;
}

.solution_content {
  display: flex;
  max-width: 1238px;
  margin: 0 auto;
}

.solution_content .content__left,
.solution_content .content__right {
  flex: 1;
}

.solution_content .content__left ul {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
}

.solution_content .content__left ul li {
  max-width: none;
  width: auto;
  text-align: right;
  margin-bottom: 10px;
  padding-top: 0;
  padding-bottom: 45px
}

.solution_content .content__left ul li>p {
  max-width: 435px !important;
  text-align: right;
  width: auto;
  margin-bottom: 0;
  margin-top: 10px;
}

/* FAQs */
.faqs dl {
  margin-bottom: 20px;
}

.faqs dt {
  font-family: 'roboto-bold';
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.faqs dd {
  font-family: 'roboto';
  margin-bottom: 50px;
  font-size: 18px;
  margin-left: 0;
}

.why-choose ul li img {
  width: 200px;
  margin-bottom: 40px;
}

/* Footer Styles */
#footer {
  background-color: #FFE319;
  width: 100%;
  z-index: 1;
  font-size: 14px;
  font-family: 'roboto-medium';
  color: black;
}

#footer .centered {
  padding: 30px 15px;
  max-width: 1238px;
  margin: 0 auto;
}

#footer__right {
  margin-left: auto;
  display: flex;
}

.separator {
  margin: 0 30px;
}

#footer__right a {
  transition: 0.2s ease all;
}

#footer__right a:hover {
  color: rgb(60, 98, 183);
}

#footer__docs a {
  color: black;
}

#footer__docs a:first-child {
  margin-left: 50px;
}

.app__link {
  transition: 0.2s ease all;
  margin-right: 5px;
  display: inline-block;
}

.app__link:hover img {
  filter: brightness(0) saturate(100%) invert(87%) sepia(51%) saturate(1624%) hue-rotate(340deg) brightness(105%) contrast(100%);
}

.inner {
  background: #fad9b9;
  color: #001f3f;
}

.inner ul {
  display: flex;
  flex-direction: column;
}

.inner ul li {
  margin-bottom: 0;
  width: auto;
  display: list-item;
  margin-left: 20px;
  padding: 15px 10px 10px;
}

.inner h4 {
  margin-top: 40px;
}

#title {
  padding-top: 55px;
  max-width: 1258px;
  margin: 0 auto;
}

#title h1 {
  text-transform: uppercase;
  font-size: 30px;
  font-family: 'roboto-bold';
  letter-spacing: 1px;
  padding: 0 25px;
  margin-top: 30px;
  margin-bottom: 0;
}

#content__inner {
  max-width: 1258px;
  margin: 0 auto;
  padding-bottom: 100px;
}

@media only screen and (max-width: 960px) {
  body .challenge {
    background-image: none;
    background-position: -280px;
    /* background-size: 940px; */
    background-repeat: no-repeat;
  }

  #menu {
    margin-top: 30px;
    margin-left: 0;
  }

  .centered {
    flex-direction: column;
  }

  article {
    margin-bottom: 0;
  }

  #content .centered {
    flex-direction: column;
    padding-bottom: 0;
  }

  #content__left {
    padding: 10px;
    width: auto;
    text-align: center;
  }

  #content__left h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  #content__left h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 24px;
  }

  #content__left h4 {
    font-size: 22px;
  }

  .content__links {
    flex-direction: column;
    display: inline-block;
  }

  .app__link {
    margin-bottom: 10px;
  }

  #content__right {
    padding-right: 0;
  }

  #content__right img {
    width: 359px;
    margin-bottom: -46px;
  }

  #content__white {
    padding-bottom: 0;
  }

  .testimonials .testimonial-item {
    flex-direction: column;
  }

  .testimonials blockquote {
    width: 95%;
  }

  .solution_content {
    flex-direction: column;
  }

  .solution_content .content__left ul li,
  .solution_content .content__left ul li>p {
    text-align: center;
    margin: 0 auto;
  }

  ul li:last-child,
  ul li:last-child>p,
  dd:last-child {
    margin-bottom: 0;
  }

  ul li>p {
    max-width: 400px !important;
  }

  #logo img {
    width: 165px;
  }

  .solution img {
    width: 359px;
  }

  #languageSwitcher {
    margin-right: 10px;
  }

  .why-choose ul li img {
    width: 150px;
    margin-bottom: 20px;
  }

  #footer {
    position: relative;
    font-size: 12px;
  }

  #footer .centered,
  #footer__right,
  #footer__docs {
    flex-direction: column;
    text-align: center;
  }

  #footer #footer__copyright {
    margin-bottom: 20px;
  }

  #footer #footer__right,
  #footer #footer__right a {
    margin-left: 0;
    margin-right: 0;
  }

  #footer__docs a {
    display: block;
    margin: 10px 0;
    padding: 0;
  }

  .separator {
    display: none;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  ul li {
    width: 100%;
    padding: 0;
    margin: 0 0 20px 0;
  }

  body .email__capture img {
    max-width: 100%;
  }

  #title h1 {
    font-size: 22px;
    margin-top: 0;
  }
}

/* COMING SOON MODE */
.testimonials,
.join-us {
  display: none;
}

.coming__soon {
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 10px;
  padding: 18px;
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
}

.coming__soon p {
  font-size: 16px;
  font-family: 'roboto-bold';
  margin: 0;
  text-transform: uppercase;
}

.ready-to-manifest .coming__soon {
  border-color: black;
}

.email__capture {
  display: none;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  border-radius: 8px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.email__capture img {
  max-width: 800px;
  margin-top: 50px;
}

.email__label {
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

.email__input {
  max-width: 400px;
  width: 100%;
  padding: 20px 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.email__button {
  background-color: #6290a6;
  color: white;
  padding: 20px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  max-width: 400px;
  width: 100%;
  font-family: 'roboto-bold';
  text-transform: uppercase;
}

.email__button:hover {
  background-color: #50788d;
}

.email__response {
  margin-top: 15px;
  color: green;
}

.email__engage {
  font-family: 'roboto';
  color: black;
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 30px;
  text-align: center;
}